import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UnauthorizedComponent } from '../modules/shared/unauthorized/unauthorized.component';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(
    private readonly matDialog: MatDialog,
    public readonly toastrService: ToastrService,
  ) { }

  handleError(error: any): void {
    const status = error.status || error.Status;
    const errorResponse = error.error || error.Error;

    // Log the error
    console.error("HTTP Error:", error);

    // Handle specific HTTP status codes
    if (status === 401) {
      this.handleUnauthorized();
    } else if (status === 403) {
      this.handleForbidden();
    } else if (status === 404) {
      this.handleNotFound(errorResponse);
    } else if (status >= 500) {
      this.handleServerError(errorResponse);
    } else {
      this.handleGenericError(errorResponse);
    }
  }

  private handleNotFound(error: any): void {
    const message = error.message || error.Message;
    this.toastrService.error(message, 'Resource not found.', { timeOut: 5000 });
  }

  private handleServerError(error: any): void {
    const message = error.message || error.Message;
    this.toastrService.error(message, 'Server error occurred.', { timeOut: 5000 });
  }

  private handleGenericError(error: any): void {
    const message = error.message || error.Message;
    this.toastrService.error(message, 'An unexpected error occurred.', { timeOut: 5000 });
  }

  protected handleUnauthorized(): void {
    this.matDialog.open(UnauthorizedComponent, { disableClose: true });
  }

  protected handleForbidden(): void {
    this.matDialog.open(UnauthorizedComponent, { disableClose: true });
  }

}
