import { Validators } from "@angular/forms";
import { FormConfigInterface } from "./interfaces/form-config.interface";

export const BE_CONFIG: FormConfigInterface = {
  completeProfile: [
    { Name: "given_name", Required: () => true, Type: "text", Order: 1, Readonly: true, Hide: () => false },
    { Name: "family_name", Required: () => true, Type: "text", Order: 2, Readonly: true, Hide: () => false },
    { Name: "country_residence", Required: () => true, Type: "country-picker", Order: 6, Hide: () => true },
    { Name: "ssn", Required: () => true, Type: "text", Order: 9, Readonly: true, Hide: () => false, },
    { Name: "date_of_birth", Required: () => true, Type: "date-picker", Order: 7, Readonly: true, Hide: () => false },
    { Name: "gender", Required: () => true, Type: "gender-picker", Order: 8, Readonly: true, Hide: () => false },
    { Name: "email", Type: "text", Order: 3, Readonly: true, Hide: () => false, Required: () => true, CustomValidators: [Validators.email] },
    { Name: "phone", Type: "phone", Order: 4, Hide: () => false, Required: () => true },
    { Name: "locale", Type: "locale-picker", Order: 5, Hide: () => true, Required: () => true },
  ],
  adminCompleteProfile: [
    { Name: "given_name", Required: () => true, Type: "text", Order: 1, Readonly: false, Hide: () => false },
    { Name: "family_name", Required: () => true, Type: "text", Order: 2, Readonly: false, Hide: () => false },
    { Name: "email", Type: "text", Order: 3, Readonly: true, Hide: () => false, Required: () => true },
    { Name: "phone", Type: "phone", Order: 4, Hide: () => false, Required: () => true },
    { Name: "locale", Type: "locale-picker", Order: 5, Hide: () => false, Required: () => true },
    { Name: "country_residence", Required: () => true, Type: "country-picker", Order: 6, Hide: () => false },
    { Name: "date_of_birth", Required: () => true, Type: "date-picker", Order: 8, Readonly: false, Hide: () => false },
    { Name: "gender", Required: () => true, Type: "gender-picker", Order: 9, Readonly: false, Hide: () => false },
  ],
  personalInfo: [
    { Name: "given_name", Required: () => true, Type: "text", Order: 1, Readonly: true, Hide: () => false },
    { Name: "family_name", Required: () => true, Type: "text", Order: 2, Readonly: true, Hide: () => false },
    { Name: "country_residence", Required: () => true, Type: "country-picker", Order: 3, Hide: () => false },
    { Name: "ssn", Required: () => true, Type: "text", Order: 4, Readonly: true, Hide: () => false, },
    { Name: "date_of_birth", Required: () => true, Type: "date-picker", Order: 5, Hide: () => false, Readonly: true },
    { Name: "gender", Required: () => true, Type: "gender-picker", Order: 6, Readonly: true, Hide: () => false },
  ],
  contact: [
    { Name: "country_address", Required: () => false, Type: "country-picker", Order: 0, Hide: () => false },
    { Name: "city", Required: () => false, Type: "text", Order: 0, Hide: () => false },
    { Name: "zipcode", Required: () => false, Type: "text", Order: 0, Hide: () => false },
    { Name: "address", Required: () => false, Type: "text", Order: 0, Hide: () => false },
    { Name: "phone", Required: () => true, Type: "phone", Order: 0, Hide: () => false, CustomLabelTranslationKey: "phoneLabel" },
    { Name: "email", Required: () => true, Type: "text", Order: 0, Readonly: false, Hide: () => false, CustomLabelTranslationKey: "emailLabel2", },
  ],
};
