export function isNewErrorSchema(inputError: any): boolean {
  const lowerCaseErrors = (
    typeof inputError === 'object' &&
    inputError !== null &&
    'success' in inputError &&
    'message' in inputError &&
    'data' in inputError &&
    'errorCode' in inputError &&
    'errors' in inputError
  );
  const upperCaseErrors = (
    typeof inputError === 'object' &&
    inputError !== null &&
    'Success' in inputError &&
    'Message' in inputError &&
    'Data' in inputError &&
    'ErrorCode' in inputError &&
    'Errors' in inputError
  );

  return lowerCaseErrors || upperCaseErrors;
}
